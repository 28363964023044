import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import FlipCountdown from '@rumess/react-flip-countdown';
import moment from 'moment';
import { FeedbackContainerV2 } from '../../components/Feedback/FeedbackContainerV2';
import { FeedbackHeader } from '../../components/Feedback/FeedbackHeader';
import ScreenShotsTab from '../../components/PageComponent/ScreenShotsTab';
import TabV2 from '../../components/Pages/FeedbackComponent/TabV2';
import { ToastManager } from '../../components/Toast/ToastManager';
import TranscriptPage from '../../components/TranscriptPage';
import BackArrowIcon from '../../assets/images/arrow-back-nostroke.svg';
import interviewCompleted from '../../assets/images/interview-completed.svg';
import regenerateTranscript from '../../assets/images/regenerate_transcript.svg';
import RightTick from '../../assets/images/right-tick.svg';
import clock from '../../images/clock.svg';
import logo from '../../images/incruiter-logo.svg';
import { getAllScreenshots, getFeedback, getRegeneratedAiSummary, getSkills } from '../../redux/action';
import UpdateFeedbackForMobileScreen from '../UpdateFeedbackForMobileScreen/UpdateFeedbackForMobileScreen';
import RegenerateTranscript from './RegenerateTranscript';
import UploadVideo from './UploadVideo';
import { isMobileDevice } from '../../common/utils';
import { ROLES } from '../../common/constants';

const CommonFeedbackRoom = () => {
    const dispatch = useDispatch();
    const { interviewId, roomId, role } = useParams();
    const [question, setQuestion] = useState('');
    const rolePage = role ? role : ROLES.INTERVIEWER;
    const isAdminRole = rolePage === ROLES.ADMIN;
    useEffect(() => {
        if (roomId && interviewId) {
            dispatch(getFeedback({ roomId: roomId, interviewId: interviewId }));
            dispatch(getAllScreenshots({ roomId: roomId, interviewId: interviewId }));
        }
    }, [dispatch, roomId, interviewId]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [checkDateAndTime, setCheckDateAndTime] = useState(true);
    const [remainingTime, setRemainingTime] = useState('');
    const [isSideOpen, setIsSideOpen] = useState(true);

    const fetchLoading = useSelector((state) => state.Interview.fetchLoading);
    const fetchLoadingFail = useSelector((state) => state.Interview.fetchLoadingFail);
    const loading = useSelector((state) => state.Interview.loading);
    const skills = useSelector((state) => state.Interview.skills);
    const feedbackDetails = useSelector((state) => state.Interview.feedbackDetails);
    const regeneratedAiSummaryLoader = useSelector((state) => state.Interview.regeneratedAiSummaryLoader);
    const regeneratedAiSummary = useSelector((state) => state.Interview.regeneratedAiSummary);
    const allScreenshots = useSelector((state) => state.Interview.allScreenshots);
    const [page, setPage] = useState(0);
    const [isTimeReached, setIsTimeReached] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [fetchTranscriptFail, setFetchTranscriptFail] = useState(fetchLoadingFail);
    const MasterSkills = useMemo(() => {
        const updatedMasterSkills = [];
        if (skills && skills.length > 0) {
            skills.forEach((itm) => {
                updatedMasterSkills.push({
                    value: itm.name,
                    label: itm.name,
                });
            });
        }
        return updatedMasterSkills;
    }, [skills]);
    const [feedbackForm, setFeedbackForm] = useState({
        skills: [],
        screeningQuestions: [],
        finalRemark: null,
        finalRating: null,
        status: null,
        interviewId: null,
        codeImages: [],
        suspectedProxy: false,
        key: '',
        activeTab: 1,
        ratingType: 1,
        code: [],
        codeEditor: null,
        roomId: roomId,
        transcriptStatus: false,
        transcript: [],
        interviewSummaryStatus: false,
        interviewSummary: [],
        codeUpdated: false,
    });
    const handleToggleSideMenu = () => {
        setIsSideOpen(!isSideOpen);
    };

    const handleRegenerateAiSummary = () => {
        if (interviewId) {
            if (clickCount < 2) {
                dispatch(getRegeneratedAiSummary({ interviewId }));
                setClickCount(clickCount + 1);
            } else {
                ToastManager.addToast({
                    title: 'You are not allowed to Regenerate AI Summary more than twice',
                });
            }
        } else {
            ToastManager.addToast({
                title: 'No AI Summary available.',
            });
        }
    };

    useEffect(() => {
        let tmpSkills = [],
            tmpScreeningQues = [];
        if (feedbackDetails) {
            if (feedbackDetails?.interviewId?.feedback) {
                let mandatorySkills = feedbackDetails.interviewId?.skills
                    ? feedbackDetails.interviewId?.skills.length > 0
                        ? feedbackDetails.interviewId?.skills.filter((nItem) => nItem?.skillType === 'mandatory')
                        : ''
                    : '';
                let optionalSkills = feedbackDetails.interviewId?.skills
                    ? feedbackDetails.interviewId?.skills.length > 0
                        ? feedbackDetails.interviewId?.skills.filter((nItem) => nItem?.skillType === 'optional')
                        : ''
                    : '';
                let screeningQuestionSkills = feedbackDetails.interviewId?.screeningQuestions
                    ? feedbackDetails.interviewId?.screeningQuestions.length > 0
                        ? feedbackDetails.interviewId?.screeningQuestions
                        : ''
                    : '';
                feedbackDetails?.interviewId?.feedback?.skills &&
                    feedbackDetails.interviewId?.feedback?.skills.length > 0 &&
                    feedbackDetails.interviewId?.feedback?.skills.map((skillList, index) => {
                        let object = {
                            skill: skillList.skill,
                            rating: skillList.rating ? skillList.rating : '',
                            comment: skillList.comment ? skillList.comment : '',
                            commentRequired:
                                skillList.skillType === 'mandatory'
                                    ? mandatorySkills
                                        ? mandatorySkills.length > 0
                                            ? mandatorySkills[0].commentRequired
                                            : false
                                        : false
                                    : skillList.skillType === 'optional'
                                      ? optionalSkills
                                          ? optionalSkills.length > 0
                                              ? optionalSkills[0].commentRequired
                                              : false
                                          : false
                                      : false,
                            commentLength:
                                skillList.skillType === 'mandatory'
                                    ? mandatorySkills
                                        ? mandatorySkills.length > 0
                                            ? mandatorySkills[0].commentLength
                                            : { min: 75, max: 1000 }
                                        : { min: 75, max: 1000 }
                                    : skillList.skillType === 'optional'
                                      ? optionalSkills
                                          ? optionalSkills.length > 0
                                              ? optionalSkills[0].commentLength
                                              : { min: 0, max: 1000 }
                                          : { min: 0, max: 1000 }
                                      : { min: 0, max: 1000 },
                            position: index,
                            skillType: skillList.skillType,
                            delete: isAdminRole,
                            type: 'default',
                            commentBoxVisible:
                                skillList.skillType === 'mandatory'
                                    ? mandatorySkills
                                        ? mandatorySkills.length > 0
                                            ? mandatorySkills[0].commentRequired
                                            : false
                                        : false
                                    : skillList.skillType === 'optional'
                                      ? optionalSkills
                                          ? optionalSkills.length > 0
                                              ? optionalSkills[0].commentRequired
                                              : false
                                          : false
                                      : false,
                        };
                        tmpSkills.push(object);
                    });
                feedbackDetails?.interviewId?.feedback?.screeningQuestions &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.length > 0 &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.map((screeningQuestionsList, index) => {
                        let object = {
                            question: screeningQuestionsList.question,
                            comment: screeningQuestionsList?.comment,
                            commentRequired: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentRequired
                                    : false
                                : false,
                            commentLength: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentLength
                                    : { min: 50, max: 500 }
                                : { min: 50, max: 500 },
                            position: index,
                            delete: isAdminRole,
                            type: 'default',
                            commentBoxVisible: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentRequired
                                    : false
                                : false,
                        };
                        tmpScreeningQues.push(object);
                    });
            } else {
                let newLists = [];
                if (
                    (feedbackDetails?.interviewId?.ratingType === 2 &&
                        feedbackDetails?.interviewId?.candidate?.experience > 9.9) ||
                    feedbackDetails?.interviewId?.ratingType === 1
                ) {
                    newLists = [
                        {
                            name: 'Confidence',
                            type: 'soft',
                        },
                        {
                            name: 'Communication',
                            type: 'soft',
                        },
                        {
                            name: 'Pressure Handling',
                            type: 'soft',
                        },
                        {
                            name: 'Approach & Attitude',
                            type: 'analytics',
                        },
                        {
                            name: 'Problem Solving',
                            type: 'analytics',
                        },
                        {
                            name: 'Result Oriented',
                            type: 'analytics',
                        },
                        {
                            name: 'Decision Making',
                            type: 'leadership',
                        },
                        {
                            name: 'Team Management',
                            type: 'leadership',
                        },
                        {
                            name: 'Accountability',
                            type: 'leadership',
                        },
                    ];
                } else {
                    newLists = [
                        {
                            name: 'Confidence',
                            type: 'soft',
                        },
                        {
                            name: 'Communication',
                            type: 'soft',
                        },
                        {
                            name: 'Pressure Handling',
                            type: 'soft',
                        },
                        {
                            name: 'Approach & Attitude',
                            type: 'analytics',
                        },
                        {
                            name: 'Problem Solving',
                            type: 'analytics',
                        },
                        {
                            name: 'Result Oriented',
                            type: 'analytics',
                        },
                    ];
                }
                feedbackDetails?.interviewId?.skills &&
                    feedbackDetails?.interviewId?.skills.length > 0 &&
                    feedbackDetails?.interviewId?.skills.map((skillList, index) => {
                        let object = {
                            skill: skillList.name,
                            rating: '',
                            comment: '',
                            commentRequired: skillList.commentRequired,
                            commentLength: skillList.commentLength,
                            position: index,
                            type: 'default',
                            skillType: skillList.skillType,
                            delete: isAdminRole,
                            commentBoxVisible: skillList.commentRequired ? skillList.commentRequired : false,
                        };
                        tmpSkills.push(object);
                    });
                newLists.map((skillList) => {
                    let object = {
                        skill: skillList.name,
                        rating: '',
                        comment: '',
                        commentRequired: false,
                        commentLength: 0,
                        position: tmpSkills.length,
                        skillType: skillList.type,
                        delete: false,
                        type: 'default',
                        commentBoxVisible: false,
                    };
                    tmpSkills.push(object);
                });
                feedbackDetails?.interviewId?.screeningQuestions &&
                    feedbackDetails?.interviewId?.screeningQuestions.length > 0 &&
                    feedbackDetails?.interviewId?.screeningQuestions.map((screeningQuestionsList, index) => {
                        let object = {
                            question: screeningQuestionsList.question,
                            comment: '',
                            commentRequired: screeningQuestionsList.commentRequired,
                            commentLength: screeningQuestionsList.commentLength,
                            position: index,
                            type: 'default',
                            delete: isAdminRole,
                            commentBoxVisible: screeningQuestionsList.commentRequired
                                ? screeningQuestionsList.commentRequired
                                : false,
                        };
                        tmpScreeningQues.push(object);
                    });
            }
            const transcript =
                feedbackDetails?.interviewId?.transcript?.length > 0
                    ? feedbackDetails?.interviewId?.transcript
                    : feedbackDetails?.interviewId?.feedback?.transcript;
            const interviewSummary =
                feedbackDetails?.interviewId?.interviewSummary?.length > 0
                    ? feedbackDetails?.interviewId?.interviewSummary
                    : feedbackDetails?.interviewId?.feedback?.interviewSummary;
            setFeedbackForm((prevState) => ({
                ...prevState,
                skills: feedbackForm?.skills.length > 0 ? feedbackForm?.skills : tmpSkills,
                screeningQuestions:
                    feedbackForm?.screeningQuestions.length > 0 ? feedbackForm?.screeningQuestions : tmpScreeningQues,
                finalRemark: feedbackForm?.finalRemark
                    ? feedbackForm?.finalRemark
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.finalRemark === null
                          ? ''
                          : feedbackDetails?.interviewId?.feedback?.finalRemark
                      : '',
                finalRating: feedbackForm?.finalRating
                    ? feedbackForm?.finalRating
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.finalRating === null
                          ? ''
                          : feedbackDetails?.interviewId?.feedback?.finalRating
                      : '',
                status: feedbackForm?.status
                    ? feedbackForm?.status
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.status === null
                          ? ''
                          : feedbackDetails?.interviewId?.feedback?.status
                      : '',
                interviewId: feedbackForm?.interviewId ? feedbackForm?.interviewId : feedbackDetails?.interviewId?._id,
                suspectedProxy: feedbackForm?.suspectedProxy
                    ? feedbackForm?.suspectedProxy
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.suspectedProxy
                      : false,
                ratingType: feedbackDetails?.interviewId?.ratingType,
                codeImages: feedbackDetails?.interviewId?.feedback?.codeImages
                    ? feedbackDetails?.interviewId?.feedback?.codeImages
                    : [],
                codeEditor: feedbackDetails?.interviewId?.feedback?.code
                    ? feedbackDetails?.interviewId?.feedback?.code[0]
                    : '',
                code: feedbackDetails?.interviewId?.feedback?.code ?? [],
                transcript: transcript || [],
                interviewSummary: interviewSummary || [],
            }));
        }
    }, [feedbackDetails]);

    useEffect(() => {
        if (regeneratedAiSummary?.interviewSummary) {
            dispatch(getFeedback({ roomId: roomId, interviewId: interviewId }));
        }
    }, [regeneratedAiSummary]);

    useEffect(() => {
        if (feedbackDetails) {
            if (feedbackDetails?.interviewId?.scheduledDate) {
                var startDate = new Date(feedbackDetails?.interviewId?.scheduledDate);
                startDate.setHours(startDate.getHours() - 5);
                startDate.setMinutes(startDate.getMinutes() - 15);
                var currentDate = new Date();
                currentDate.setHours(currentDate.getHours());
                currentDate.setMinutes(currentDate.getMinutes());
                if (currentDate >= startDate) {
                    setCheckDateAndTime(false);
                } else {
                    setCheckDateAndTime(true);
                    setRemainingTime(moment(startDate).format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        }
    }, [feedbackDetails, isTimeReached]);

    // for checking remaining time is equal to current time
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
            if (currentTime === remainingTime) {
                setIsTimeReached(true);
            }
        }, 1000); // Check every second for current time update

        return () => clearInterval(interval);
    }, [remainingTime]);

    const handleRatingComment = (type, mode, value, index) => {
        if (mode === 'comment' && type !== 'screeningQuestions' && value.length > 1000) {
            ToastManager.addToast({
                title: "Comments can't be more than 1000 characters.",
            });
        } else {
            let tmpData = feedbackForm[type];
            tmpData[index][mode] = value;
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: tmpData,
                codeUpdated: true,
            }));
        }
        setIsUpdate(!isUpdate);
    };

    const handleRemarks = (type, value) => {
        let status = feedbackForm?.status;
        if (feedbackForm?.ratingType === 2 && type === 'finalRating') {
            if ('finalRating' && value > -1 && value < 3) {
                status = 'very_poor';
            } else if ('finalRating' && value > 2 && value < 5) {
                status = 'poor';
            } else if ('finalRating' && value === 5) {
                status = 'average';
            } else if ('finalRating' && value === 6) {
                status = 'above_average';
            } else if ('finalRating' && value === 7) {
                status = 'good';
            } else if ('finalRating' && value === 8) {
                status = 'very_good';
            } else if ('finalRating' && value > 8 && value < 11) {
                status = 'excellent';
            }
        }
        let tmpData = feedbackForm;
        tmpData[type] = value;
        if (type === 'finalRating') {
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: value,
                status: status,
                codeUpdated: true,
            }));
        } else {
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: value,
                codeUpdated: true,
            }));
        }
        setIsUpdate(!isUpdate);
    };
    const handleDelete = (data, type, index) => {
        let tmpData = data ? data : [];
        tmpData.splice(index, 1);
        let i = 0;
        tmpData.length > 0 &&
            tmpData.map((itm) => {
                itm.position = i;
                i = i + 1;
            });
        setFeedbackForm((prevState) => ({
            ...prevState,
            [type]: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(!isUpdate);
    };
    const handleAdd = (data, type, mode) => {
        let tmpData = data ? data : [];
        if (type === 'screeningQuestions') {
            if (question === '') {
                ToastManager.addToast({
                    title: "Question can't be empty.",
                });
            } else if (question.length < 5) {
                ToastManager.addToast({
                    title: `Question can't be less than 5 characters.`,
                });
            } else if (
                feedbackForm?.screeningQuestions.length >=
                feedbackDetails?.interviewId?.screeningQuestionsSummary?.QuestionCount?.max
            ) {
                ToastManager.addToast({
                    title: `No Of Questions Cannot Exceed More Than ${feedbackDetails?.interviewId?.screeningQuestionsSummary?.QuestionCount?.max}`,
                });
            } else {
                tmpData.push({
                    question: question,
                    comment: '',
                    commentRequired: false,
                    commentLength: 0,
                    position: tmpData.length,
                    delete: true,
                    type: 'custom',
                    commentBoxVisible: false,
                });
            }
            setQuestion('');
        } else {
            let skillCount = tmpData.filter((itm) => itm.skill === '');
            let mandatorySkills = feedbackForm?.skills.filter((itm) => itm.skillType === 'mandatory');
            let optionalSkills = feedbackForm?.skills.filter((itm) => itm.skillType === 'optional');
            if (skillCount && skillCount.length > 0) {
                ToastManager.addToast({
                    title: "Skill name can't be empty.",
                });
            } else if (
                mode === 'mandatory' &&
                mandatorySkills.length >= feedbackDetails?.interviewId?.skillsSummary?.mandatorySkillCount?.max
            ) {
                ToastManager.addToast({
                    title: `Mandatory Skills Cannot Not Be More Than ${feedbackDetails?.interviewId?.skillsSummary?.mandatorySkillCount?.max}`,
                });
            } else if (
                mode === 'optional' &&
                optionalSkills.length >= feedbackDetails?.interviewId?.skillsSummary?.optionalSkillCount?.max
            ) {
                ToastManager.addToast({
                    title: `Optional Skills Cannot Not Be More Than ${feedbackDetails?.interviewId?.skillsSummary?.optionalSkillCount?.max}`,
                });
            } else {
                tmpData.push({
                    skill: '',
                    rating: '',
                    comment: '',
                    commentRequired: false,
                    commentLength: 0,
                    position: tmpData.length,
                    skillType: mode,
                    delete: true,
                    type: 'custom',
                    commentBoxVisible: false,
                });
            }
        }
        setFeedbackForm((prevState) => ({
            ...prevState,
            [type === 'skills' ? 'skills' : 'screeningQuestions']: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(false);
    };
    const handleChangeSkill = (data, value, index) => {
        let tmpData = data ? data : [];
        tmpData[index]['skill'] = value;
        setFeedbackForm((prevState) => ({
            ...prevState,
            skills: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(!isUpdate);
    };
    const handleCommentBoxVisible = (data, value, index, type) => {
        let tmpData = data ? data : [];
        tmpData[index]['commentBoxVisible'] = value;
        setFeedbackForm((prevState) => ({
            ...prevState,
            [type === 'skills' ? 'skills' : 'screeningQuestions']: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(!isUpdate);
    };
    useEffect(() => {
        if (!skills) {
            dispatch(getSkills());
        }
    }, [dispatch, skills]);
    useEffect(() => {}, [feedbackForm]);

    // updating screenWidth whenever window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderContent = () => {
        if (feedbackForm?.activeTab === 1)
            return (
                <Flex css={{ size: '100%' }} direction="column">
                    <Flex
                        direction="column"
                        css={{
                            height: screenWidth > 768 ? '90vh' : '550px',
                        }}
                    >
                        <Flex
                            direction="column"
                            align="center"
                            className="recordingDiv"
                            css={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                justifyContent: screenWidth > 768 ? 'center' : 'flex-start',
                            }}
                        >
                            <Flex
                                css={{
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '40px',
                                    lineHeight: '55px',
                                    color: '#9396A0',
                                    textAlign: 'center',
                                    background:
                                        fetchLoading || fetchTranscriptFail
                                            ? 'rgba(25, 25, 36, 0.4)'
                                            : 'rgba(25, 25, 36, 1)',
                                    width: '98%',
                                    height: screenWidth > 768 ? '85%' : '100%',
                                    margin: '0 auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    variant="body1"
                                    css={{
                                        color: '#ffffff',
                                        fontWeight: '$regular',
                                        'font-size': '30px',
                                        'line-height': '41px',
                                    }}
                                >
                                    <img src={interviewCompleted} alt="Interview Completed" />
                                </Text>
                                <Text
                                    variant="body1"
                                    css={{
                                        color: '#ffffff',
                                        'font-size': '30px',
                                        'line-height': '41px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '18px',
                                        lineHeight: '25px',
                                        letterSpacing: '0.04em',
                                    }}
                                >
                                    <img src={RightTick} alt="download" className="ext-file-download" />
                                    <span
                                        style={{
                                            padding: '10px',
                                            display: 'inline-block',
                                            paddingTop: '0px',
                                        }}
                                    >
                                        The interview has been completed!
                                    </span>
                                </Text>
                                <Text
                                    variant="body1"
                                    css={{
                                        color: '#ffffff',
                                        'font-size': '30px',
                                        'line-height': '41px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        lineHeight: '25px',
                                        letterSpacing: '0.04em',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    Please provide feedback using the form on the right panel
                                </Text>
                                {isAdminRole && (
                                    <div className="upload-wrapper">
                                        <UploadVideo roomId={roomId} />
                                        <RegenerateTranscript
                                            regenerateTranscript={regenerateTranscript}
                                            fetchLoading={fetchLoading}
                                            roomId={roomId}
                                            interviewId={interviewId}
                                            fetchTranscriptFail={fetchTranscriptFail}
                                            setFetchTranscriptFail={setFetchTranscriptFail}
                                        />
                                    </div>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            );
        if (feedbackForm?.activeTab === 3)
            return (
                <TranscriptPage
                    feedbackForm={feedbackForm}
                    interviewId={interviewId}
                    rolePage={rolePage}
                    handleRegenerateAiSummary={handleRegenerateAiSummary}
                    clickCount={clickCount}
                />
            );
        return (
            <Flex css={{ size: '100%' }} direction="column">
                <Flex
                    direction="column"
                    css={{
                        height: '90vh',
                        overflowY: 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <ScreenShotsTab
                        images={allScreenshots?.screenshots}
                        roomId={roomId}
                        interviewId={interviewId}
                        feedbackId={feedbackDetails?.interviewId?.feedback?._id}
                    />
                </Flex>
            </Flex>
        );
    };

    const renderMain = () => {
        if (checkDateAndTime && remainingTime)
            return (
                <Flex css={{ size: '100%' }} direction="column">
                    <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                        <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                            <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                                <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                            </Flex>
                        </Flex>
                        <div className="early-join-div">
                            <div className="center-img-div d-center">
                                <img src={clock} alt=""></img>
                            </div>
                            <p className="f-2v f-reg earlyjoin-p">You are too early to update this Feedback</p>
                            <p className="f-2v f-reg earlyjoin-p">Time remaining to update the feedback</p>
                            <div className="early-time-div-wrapper ">
                                <FlipCountdown
                                    size="large"
                                    hideYear
                                    titlePosition="bottom"
                                    hideMonth
                                    endAtZero
                                    endAt={remainingTime}
                                />
                            </div>
                        </div>
                    </Box>
                </Flex>
            );
        if (isMobileDevice()) return <UpdateFeedbackForMobileScreen />;
        return (
            <Flex css={{ size: '100%' }} direction="column">
                <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                    <Flex justify="between" align="center" css={{ margin: '11px 0 10px 24px' }}>
                        <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                    </Flex>
                    <TabV2
                        form={feedbackForm}
                        setForm={setFeedbackForm}
                        role={rolePage}
                        isSideOpen={isSideOpen}
                        carSystem={false}
                    />
                    <Box
                        css={{
                            width: '100%',
                            flex: '1 1 0',
                            minHeight: 0,
                        }}
                        data-testid="conferencing"
                    >
                        <div
                            style={{
                                width: screenWidth > 768 && isSideOpen ? '70%' : '98%',
                                flex: '1 1 0px',
                                minHeight: '0px',
                                height: '100%',
                                background: feedbackForm?.activeTab === 3 ? '#191924' : '#2E2E41',
                                borderRadius: '0px',
                            }}
                        >
                            {renderContent()}
                        </div>
                    </Box>
                    <div
                        className={`arrow_icon_container ${isSideOpen ? 'rightOpened' : 'rightClosed'}`}
                        onClick={() => handleToggleSideMenu()}
                    >
                        <img
                            src={BackArrowIcon}
                            alt=""
                            className={`sidebar-back-arrow${isSideOpen ? ' rotate-180' : ''}`}
                        />
                    </div>
                </Box>
                {!loading ? (
                    isSideOpen && (
                        <div
                            className="part-2 h-100 feedback-container-v2"
                            style={{
                                width: screenWidth > 768 ? '30%' : '100%',
                                top: screenWidth < 768 && '600px',
                            }}
                        >
                            <FeedbackHeader interviewDetails={feedbackDetails?.interviewId} form={feedbackForm} />
                            <FeedbackContainerV2
                                form={feedbackForm}
                                MasterSkills={MasterSkills}
                                handleRatingComment={handleRatingComment}
                                handleRemarks={handleRemarks}
                                handleDelete={handleDelete}
                                handleAdd={handleAdd}
                                handleChangeSkill={handleChangeSkill}
                                handleCommentBoxVisible={handleCommentBoxVisible}
                                setForm={setFeedbackForm}
                                userType="Interviewer"
                                role={rolePage}
                                roomId={roomId}
                                allScreenshots={allScreenshots}
                                interviewDetails={feedbackDetails?.interviewId}
                                setPage={setPage}
                                page={page}
                                setQuestion={setQuestion}
                                question={question}
                            />
                        </div>
                    )
                ) : (
                    <Loader />
                )}
                {regeneratedAiSummaryLoader && <Loader />}
            </Flex>
        );
    };

    return renderMain();
};
const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});
const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader" />
        </div>
    );
});
export default CommonFeedbackRoom;
