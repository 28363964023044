import React from 'react';
import { useParams } from 'react-router-dom';
function ToggleSwitch({ form, setForm, socket, roomId }) {
    const { role } = useParams();
    const handleChange = () => {
        setForm((prevState) => ({
            ...prevState,
            suspectedProxy: !form.suspectedProxy,
            codeUpdated: true,
        }));
        if (socket) {
            let nData = {
                suspectedProxy: !form.suspectedProxy,
                roleName: role,
            };
            socket?.emit('codeWritten', nData, roomId);
        }
    };

    return (
        <div className="suspected-div">
            <h4 className="f-14 f-sem-bol text-theme-white text-left">Suspected Proxy : </h4>
            <label className="toggle-switch">
                <input type="checkbox" checked={form.suspectedProxy} onChange={handleChange} />
                <span className="switch" />
            </label>
        </div>
    );
}

export default ToggleSwitch;
