import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import FlipCountdown from '@rumess/react-flip-countdown';
import moment from 'moment';
import CarFeedbackContainer from '../../components/Feedback/CarFeedbackContainer';
import { FeedbackHeader } from '../../components/Feedback/FeedbackHeader';
import TabV2 from '../../components/Pages/FeedbackComponent/TabV2';
import { ToastManager } from '../../components/Toast/ToastManager';
import TranscriptPage from '../../components/TranscriptPage';
import BackArrowIcon from '../../assets/images/arrow-back-nostroke.svg';
import interviewCompleted from '../../assets/images/interview-completed.svg';
import regenerateTranscript from '../../assets/images/regenerate_transcript.svg';
import RightTick from '../../assets/images/right-tick.svg';
import clock from '../../images/clock.svg';
import logo from '../../images/incruiter-logo.svg';
import { getFeedback, getRegeneratedAiSummary } from '../../redux/action';
import UpdateFeedbackForMobileScreen from '../UpdateFeedbackForMobileScreen/UpdateFeedbackForMobileScreen';
import RegenerateTranscript from './RegenerateTranscript';
import UploadVideo from './UploadVideo';
import { isMobileDevice } from '../../common/utils';
import { checkFileValidation } from '../../utils/helpers';
import { ROLES } from '../../common/constants';

const CarFeedbackRoom = () => {
    const dispatch = useDispatch();
    const { interviewId, roomId, role } = useParams();
    const rolePage = role || ROLES.INTERVIEWER;
    const isAdminRole = rolePage === ROLES.ADMIN;
    const { regeneratedAiSummary, feedbackDetails, regeneratedAiSummaryLoader, fetchLoading, fetchLoadingFail } =
        useSelector((state) => ({
            regeneratedAiSummary: state.Interview.regeneratedAiSummary,
            feedbackDetails: state.Interview.feedbackDetails,
            regeneratedAiSummaryLoader: state.Interview.regeneratedAiSummaryLoader,
            fetchLoading: state.Interview.fetchLoading,
            fetchLoadingFail: state.Interview.fetchLoadingFail,
            loading: state.Interview.loading,
        }));
    const [clickCount, setClickCount] = useState(0);
    const [checkDateAndTime, setCheckDateAndTime] = useState(true);
    const [remainingTime, setRemainingTime] = useState('');
    const [isSideOpen, setIsSideOpen] = useState(true);
    const [isTimeReached, setIsTimeReached] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [fetchTranscriptFail, setFetchTranscriptFail] = useState(fetchLoadingFail);
    const [feedbackForm, setFeedbackForm] = useState({
        carSystem: false,
        carFeedbackFileName: '',
        carFeedback: '',
        carSampleLink: '',
        finalRemark: null,
        finalRating: null,
        status: null,
        interviewId: interviewId,
        key: '',
        activeTab: 1,
        ratingType: 2,
        code: '',
        codeEditor: '',
        role: rolePage,
        roomId: roomId,
        transcriptStatus: false,
        transcript: [],
        interviewSummaryStatus: false,
        interviewSummary: [],
        tabPage: 0,
        codeUpdated: false,
    });

    useEffect(() => {
        if (interviewId || regeneratedAiSummary?.interviewSummary) {
            dispatch(getFeedback({ interviewId, roomId }));
        }
    }, [dispatch, interviewId, regeneratedAiSummary]);

    useEffect(() => {
        if (feedbackDetails) {
            const feedback = feedbackDetails?.interviewId?.feedback || {};
            setFeedbackForm((prevState) => ({
                ...prevState,
                carSampleLink: feedbackDetails?.interviewId?.carFile || '',
                carFeedbackFileName: feedback?.carFeedbackFileName || '',
                code: prevState.codeEditor || feedback?.code || '',
                codeEditor: prevState.codeEditor || feedback?.code || '',
                finalRating: prevState.finalRating || feedback.finalRating || '',
                status: prevState.status || feedback.status || '',
                transcript: feedbackDetails?.interviewId?.transcript ?? [],
                interviewSummary: feedbackDetails?.interviewId?.interviewSummary ?? [],
            }));

            if (feedbackDetails?.interviewId?.scheduledDate) {
                const startDate = new Date(feedbackDetails?.interviewId?.scheduledDate);
                startDate.setHours(startDate.getHours() - 5);
                startDate.setMinutes(startDate.getMinutes() - 15);
                const currentDate = new Date();
                if (currentDate >= startDate) {
                    setCheckDateAndTime(false);
                } else {
                    setCheckDateAndTime(true);
                    setRemainingTime(moment(startDate).format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        }
    }, [feedbackDetails, isTimeReached]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (moment().format('YYYY-MM-DD HH:mm:ss') === remainingTime) {
                setIsTimeReached(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [remainingTime]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleToggleSideMenu = () => {
        setIsSideOpen(!isSideOpen);
    };

    const handleRegenerateAiSummary = () => {
        if (interviewId) {
            if (clickCount < 2) {
                dispatch(getRegeneratedAiSummary({ interviewId }));
                setClickCount(clickCount + 1);
            } else {
                ToastManager.addToast({
                    title: 'You are not allowed to Regenerate AI Summary more than twice',
                });
            }
        } else {
            ToastManager.addToast({
                title: 'No AI Summary available.',
            });
        }
    };

    const handleRemarks = (type, value) => {
        if (type === 'carFeedback') {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const { files } = value?.target;
            if (!files || files.length === 0) {
                setFeedbackForm({ ...feedbackForm, [type]: '' });
                ToastManager.addToast({ title: "File can't be empty" });
                return;
            }

            const fileValidationResult = checkFileValidation(files[0]);
            if (fileValidationResult?.status) {
                setFeedbackForm({ ...feedbackForm, [type]: files[0] });
            } else {
                setFeedbackForm({ ...feedbackForm, [type]: '' });
                ToastManager.addToast({ title: fileValidationResult?.message });
            }
        } else {
            let status = feedbackForm?.status;
            if (type === 'finalRating') {
                status = getStatusFromRating(value);
            }
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: value,
                codeUpdated: true,
                ...(type === 'finalRating' && { status }),
            }));
        }
    };

    const getStatusFromRating = (value) => {
        if (value >= 0 && value < 3) return 'very_poor';
        if (value >= 3 && value < 5) return 'poor';
        if (value === 5) return 'average';
        if (value === 6) return 'above_average';
        if (value === 7) return 'good';
        if (value === 8) return 'very_good';
        if (value > 8 && value <= 10) return 'excellent';
        return '';
    };

    const renderContent = () => {
        if (feedbackForm?.activeTab === 3) {
            return (
                <TranscriptPage
                    feedbackForm={feedbackForm}
                    interviewId={interviewId}
                    rolePage={rolePage}
                    handleRegenerateAiSummary={handleRegenerateAiSummary}
                    clickCount={clickCount}
                />
            );
        }

        return (
            <Flex css={{ size: '100%' }} direction="column">
                <Flex
                    direction="column"
                    css={{
                        height: screenWidth > 768 ? '90vh' : '550px',
                    }}
                >
                    <Flex
                        direction="column"
                        align="center"
                        className="recordingDiv"
                        css={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            justifyContent: screenWidth > 768 ? 'center' : 'flex-start',
                        }}
                    >
                        <Flex
                            css={{
                                fontStyle: 'normal',
                                fontWeight: '700',
                                fontSize: '40px',
                                lineHeight: '55px',
                                color: '#9396A0',
                                textAlign: 'center',
                                background:
                                    fetchLoading || fetchTranscriptFail
                                        ? 'rgba(25, 25, 36, 0.4)'
                                        : 'rgba(25, 25, 36, 1)',
                                width: '98%',
                                height: screenWidth > 768 ? '85%' : '100%',
                                margin: '0 auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                variant="body1"
                                css={{
                                    color: '#ffffff',
                                    fontWeight: '$regular',
                                    fontSize: '30px',
                                    lineHeight: '41px',
                                }}
                            >
                                <img src={interviewCompleted} alt="Interview Completed" />
                            </Text>
                            <Text
                                variant="body1"
                                css={{
                                    color: '#ffffff',
                                    fontSize: '18px',
                                    lineHeight: '25px',
                                    fontWeight: '700',
                                    letterSpacing: '0.04em',
                                }}
                            >
                                <img src={RightTick} alt="download" className="ext-file-download" />
                                <span style={{ padding: '10px', display: 'inline-block', paddingTop: '0px' }}>
                                    The interview has been completed!
                                </span>
                            </Text>
                            <Text
                                variant="body1"
                                css={{
                                    color: '#ffffff',
                                    fontSize: '12px',
                                    lineHeight: '25px',
                                    fontWeight: '400',
                                    letterSpacing: '0.04em',
                                    paddingBottom: '10px',
                                }}
                            >
                                Please provide feedback using the form on the right panel
                            </Text>
                            {isAdminRole && (
                                <div className="upload-wrapper">
                                    <UploadVideo roomId={roomId} />
                                    <RegenerateTranscript
                                        regenerateTranscript={regenerateTranscript}
                                        fetchLoading={fetchLoading}
                                        roomId={roomId}
                                        interviewId={interviewId}
                                        fetchTranscriptFail={fetchTranscriptFail}
                                        setFetchTranscriptFail={setFetchTranscriptFail}
                                    />
                                </div>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        );
    };

    const renderMain = () => {
        if (checkDateAndTime && remainingTime) {
            return (
                <Flex css={{ size: '100%' }} direction="column">
                    <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                        <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                            <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                                <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                            </Flex>
                        </Flex>
                        <div className="early-join-div">
                            <div className="center-img-div d-center">
                                <img src={clock} alt=""></img>
                            </div>
                            <p className="f-2v f-reg earlyjoin-p">You are too early to update this Feedback</p>
                            <p className="f-2v f-reg earlyjoin-p">Time remaining to update the feedback</p>
                            <div className="early-time-div-wrapper ">
                                <FlipCountdown
                                    size="large"
                                    hideYear
                                    titlePosition="bottom"
                                    hideMonth
                                    endAtZero
                                    endAt={remainingTime}
                                />
                            </div>
                        </div>
                    </Box>
                </Flex>
            );
        }

        if (isMobileDevice()) {
            return <UpdateFeedbackForMobileScreen />;
        }

        return (
            <Flex css={{ size: '100%' }} direction="column">
                <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                    <Flex justify="between" align="center" css={{ margin: '11px 0 10px 24px' }}>
                        <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                    </Flex>
                    <TabV2
                        form={feedbackForm}
                        setForm={setFeedbackForm}
                        role={rolePage}
                        isSideOpen={isSideOpen}
                        carSystem
                    />
                    <Box
                        css={{
                            width: '100%',
                            flex: '1 1 0',
                            minHeight: 0,
                        }}
                        data-testid="conferencing"
                    >
                        <div
                            style={{
                                width: screenWidth > 768 && isSideOpen ? '70%' : '98%',
                                flex: '1 1 0px',
                                minHeight: '0px',
                                height: '100%',
                                background: feedbackForm?.activeTab === 3 ? '#191924' : '#2E2E41',
                                borderRadius: '0px',
                            }}
                        >
                            {renderContent()}
                        </div>
                    </Box>
                    <div
                        className={`arrow_icon_container ${isSideOpen ? 'rightOpened' : 'rightClosed'}`}
                        onClick={handleToggleSideMenu}
                    >
                        <img
                            src={BackArrowIcon}
                            alt=""
                            className={`sidebar-back-arrow${isSideOpen ? ' rotate-180' : ''}`}
                        />
                    </div>
                </Box>
                {isSideOpen && (
                    <div
                        className="part-2 h-100 feedback-container-v2"
                        style={{
                            width: screenWidth > 768 ? '30%' : '100%',
                            top: screenWidth < 768 && '600px',
                        }}
                    >
                        <FeedbackHeader
                            interviewDetails={feedbackDetails?.interviewId}
                            form={feedbackForm}
                            isSideOpen={isSideOpen}
                        />
                        <CarFeedbackContainer
                            form={feedbackForm}
                            setForm={setFeedbackForm}
                            interviewDetails={feedbackDetails?.interviewId}
                            handleRemarks={handleRemarks}
                            isSideOpen={isSideOpen}
                        />
                    </div>
                )}
                {regeneratedAiSummaryLoader && <Loader />}
            </Flex>
        );
    };

    return renderMain();
};

const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});

const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader" />
        </div>
    );
});

export default CarFeedbackRoom;
