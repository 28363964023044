// import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import Zero from '../../assets/images/0by10.svg';
import One from '../../assets/images/1by10.svg';
import Two from '../../assets/images/2by10.svg';
import Three from '../../assets/images/3by10.svg';
import Four from '../../assets/images/4by10.svg';
import Five from '../../assets/images/5by10.svg';
import Six from '../../assets/images/6by10.svg';
import Seven from '../../assets/images/7by10.svg';
import Eight from '../../assets/images/8by10.svg';
import Nine from '../../assets/images/9by10.svg';
import Ten from '../../assets/images/10by10.svg';
// import { getStatusV } from "./Helpers";

export function CircularProgressBarPage({ rating }) {
    const percentage = rating * 10;
    const colorCode = rating > 6 ? '#61BE52' : rating > 4 ? '#FC8C43' : '#E96F68';

    const getImgSrc = (rating) => {
        switch (rating) {
            case 0:
                return Zero;
            case 1:
                return One;
            case 2:
                return Two;
            case 3:
                return Three;
            case 4:
                return Four;
            case 5:
                return Five;
            case 6:
                return Six;
            case 7:
                return Seven;
            case 8:
                return Eight;
            case 9:
                return Nine;
            case 10:
                return Ten;
        }
    };

    return (
        <div className="CircularProgressbarDiv">
            <img src={getImgSrc(rating)} alt={rating} />
            {/* <CircularProgressbar
                value={percentage}
                text={' '}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    textSize: '16px',
                    pathTransitionDuration: 0.5,
                    pathColor: colorCode,
                    textColor: '#ffffff',
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                })}
            />
            <div className="CircularProgressbarText">
                <b>{rating}</b>
                <span style={{ margin: '0px' }}>/10</span>
            </div> */}

            {/* <p className="feedback-status" style={{ color: colorCode }}>
        {getStatusV(rating)?.title}
      </p> */}
        </div>
    );
}
