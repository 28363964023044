import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CodeSnippet,
    CodeSnippetImages,
    Footer,
    Header,
    OtherSkills,
    OverallSkills,
    Proctoring,
    Profile,
    // ScreeningQuestions,
    Screenshots,
    Skills,
    SkillsComment,
} from '../components/MainComponents';
import { getSoftRating, getStatusV } from '../components/OtherComponents/Helpers';
import { getFeedback } from '../redux/action';
import { capitalizeAll } from '../utils/helpers';
import '../assets/css/index.css';
import '../assets/fonts/stylesheet.css';
const Other = React.lazy(() => import('../components/Pages/Other'));
function FeedbackRoom() {
    const { roomId, interviewId } = useParams();
    const [profileForm, setProfileForm] = useState({
        image: '',
        intId: '',
        name: '',
        position: '',
        company: '',
        experience: '',
        email: '',
        phone: '',
        suspectedProxy: false,
        recommendedMode: 1,
        link: '',
    });
    const [feedbackForm, setFeedbackForm] = useState({
        proctoringScreenShots: [],
        proctoringMessage: [],
        FinalRemarksStatus: false,
        FinalRemarksData: '',
        ScreenshotsStatus: false,
        ScreenshotsData: [],
        ScreeningQuestionsStatus: false,
        ScreeningQuestionsData: [],
        CodeSnippetStatus: false,
        CodeSnippetData: '',
        SkillsCommentStatus: false,
        SkillsCommentData: [],
        SkillsStatus: false,
        SkillsData: [],
        OtherSkillsType: 1,
        OtherSkillsList: [],
        GlobalSkillRating: [],
        GlobalSkillName: [],
        proctoringLogs: {
            status: false,
            messages: [],
            screenshots: [],
        },
        skillOverAll: [
            {
                name: 'Mandatory Skills',
                type: 'mandatory',
                total: 0,
                length: 0,
            },
            { name: 'Optional Skills', type: 'optional', total: 0, length: 0 },
            { name: 'Soft Skills', type: 'soft', total: 0, length: 0 },
        ],
    });
    useEffect(() => {
        if (profileForm?.name) {
            document.title = `${capitalizeAll(profileForm?.name)} Feedback Report`;
        }
    }, [profileForm]);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.Interview.loading);
    const feedbackDetails = useSelector((state) => state.Interview.feedbackDetails);
    useEffect(() => {
        if (roomId && interviewId && !feedbackDetails) {
            dispatch(getFeedback({ roomId: roomId, interviewId: interviewId }));
        } else if (roomId && feedbackDetails) {
            if (feedbackDetails?.interviewId?.candidate) {
                setProfileForm((prevState) => ({
                    ...prevState,
                    code: feedbackDetails?.interviewId?.code,
                    name: feedbackDetails?.interviewId?.candidate?.name,
                    experience: feedbackDetails?.interviewId?.candidate?.experience,
                    email: feedbackDetails?.interviewId?.candidate?.email,
                    phone: feedbackDetails?.interviewId?.candidate?.phone,
                    position: feedbackDetails?.interviewId?.jobTitle,
                    rating: feedbackDetails?.interviewId?.feedback?.finalRating
                        ? feedbackDetails?.interviewId?.feedback?.finalRating
                        : 0,
                    company: feedbackDetails?.interviewId?.employer?.employerName
                        ? feedbackDetails?.interviewId?.employer?.employerName
                        : 'InCruiter',
                    interviewDate: feedbackDetails?.interviewId?.scheduledDate,
                    status: feedbackDetails?.interviewId?.feedback?.status,
                    link: feedbackDetails?.interviewId?.employer
                        ? feedbackDetails?.interviewId?.employer?.status
                            ? feedbackDetails?.interviewId?.employer?.logo
                            : ''
                        : '',
                }));
            }
            if (feedbackDetails?.interviewId?.reference_id) {
                setProfileForm((prevState) => ({
                    ...prevState,
                    intId: feedbackDetails?.interviewId?.reference_id?.code,
                }));
            }
            if (feedbackDetails?.interviewId?.feedback) {
                let skillRatingTmp = [];
                let skillCommentTmp = [];
                let screeningQuestionRatingTmp = [];
                let otherSkillTmp = [];
                let OtherSkillsTypeTmp = 1;
                let mc = 0,
                    mt = 0,
                    oc = 0,
                    ot = 0,
                    sc = 0,
                    st = 0;
                feedbackDetails?.interviewId?.feedback?.skills &&
                    feedbackDetails?.interviewId?.feedback?.skills.map((itm) => {
                        if (itm?.skillType === 'mandatory' || itm?.skillType === 'optional') {
                            if (itm?.rating) {
                                skillRatingTmp.push({
                                    name: itm?.skill,
                                    type: itm?.skillType,
                                    rating: parseInt(itm?.rating),
                                });
                                if (itm?.skillType === 'mandatory' && itm?.rating) {
                                    mc = mc + 1;
                                    mt = mt + parseInt(itm?.rating);
                                }
                                if (itm?.skillType === 'optional' && itm?.rating) {
                                    oc = oc + 1;
                                    ot = ot + parseInt(itm?.rating);
                                }
                            }
                            if (itm?.comment) {
                                skillCommentTmp.push({
                                    name: itm?.skill,
                                    type: itm?.skillType,
                                    color: getStatusV(itm?.rating)?.color,
                                    rating: getStatusV(itm?.rating)?.title,
                                    comment: itm?.comment,
                                });
                            }
                        } else {
                            otherSkillTmp.push({
                                name: itm?.skill,
                                type: itm?.skillType,
                                rating: itm?.rating ? parseInt(itm?.rating) : 0,
                            });
                            if (itm?.skillType === 'leadership' && itm?.rating !== '') {
                                OtherSkillsTypeTmp = 2;
                            }
                            if (itm?.rating) {
                                skillCommentTmp.push({
                                    name: itm?.skill,
                                    type: itm?.skillType,
                                    color: getSoftRating(itm?.skill, parseInt(itm?.rating))?.color,
                                    rating: getSoftRating(itm?.skill, parseInt(itm?.rating))?.value,
                                    comment: getSoftRating(itm?.skill, parseInt(itm?.rating))?.comment,
                                });
                            }
                            if (itm?.rating) {
                                sc = sc + 1;
                                st = st + parseInt(itm?.rating);
                            }
                        }
                    });
                feedbackDetails?.interviewId?.feedback?.screeningQuestions &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.map((itm) => {
                        if (itm?.comment) {
                            screeningQuestionRatingTmp.push({
                                question: itm?.question,
                                comment: itm?.comment,
                            });
                        }
                    });
                setProfileForm((prevState) => ({
                    ...prevState,
                    suspectedProxy: feedbackDetails?.interviewId?.feedback?.suspectedProxy,
                    recommendedMode:
                        feedbackDetails?.interviewId?.feedback?.finalRating > 6
                            ? 1
                            : feedbackDetails?.interviewId?.feedback?.finalRating > 4
                              ? 2
                              : 3,
                    image: feedbackDetails?.interviewId?.feedback?.candidateImage,
                }));
                setFeedbackForm((prevState) => ({
                    ...prevState,
                    resumeSummary: feedbackDetails?.interviewId?.feedback?.resumeSummary
                        ? feedbackDetails?.interviewId?.feedback?.resumeSummary
                              .replace(/\\r/g, '')
                              .replace(/\\n/g, '')
                              .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                              .replace(/\\s/g, '&nbsp;')
                              .replace('<br /><br />', '')
                        : '',
                    FinalRemarksStatus: feedbackDetails?.interviewId?.feedback.finalRemark ? true : false,
                    FinalRemarksData: feedbackDetails?.interviewId?.feedback.finalRemark,
                    ScreenshotsStatus: feedbackDetails?.interviewId?.feedback?.screenshots
                        ? feedbackDetails?.interviewId?.feedback?.screenshots.length > 0
                            ? true
                            : false
                        : false,
                    proctoringLogs: {
                        status: feedbackDetails?.interviewId?.feedback?.proctoring?.messages ? true : false,
                        messages: feedbackDetails?.interviewId?.feedback?.proctoring?.messages ?? [],
                        screenshots: feedbackDetails?.interviewId?.feedback?.proctoring?.screenshots ?? [],
                    },
                    ScreenshotsData: feedbackDetails?.interviewId?.feedback?.screenshots,
                    ScreeningQuestionsStatus: screeningQuestionRatingTmp.length > 0 ? true : false,
                    ScreeningQuestionsData: screeningQuestionRatingTmp,
                    CodeSnippetStatus: feedbackDetails?.interviewId?.feedback?.code
                        ? feedbackDetails?.interviewId?.feedback?.code.length > 0
                            ? feedbackDetails?.interviewId?.feedback?.code[0]
                                ? true
                                : false
                            : false
                        : false,
                    CodeSnippetData:
                        feedbackDetails?.interviewId?.feedback?.code.length > 0
                            ? feedbackDetails?.interviewId?.feedback?.code[0]
                            : '',
                    codeImagesStatus: feedbackDetails?.interviewId?.feedback?.codeImages
                        ? feedbackDetails?.interviewId?.feedback?.codeImages.length > 0
                            ? true
                            : false
                        : false,
                    codeImagesData:
                        feedbackDetails?.interviewId?.feedback?.codeImages.length > 0
                            ? feedbackDetails?.interviewId?.feedback?.codeImages
                            : [],
                    SkillsCommentStatus: skillCommentTmp.length > 0 ? true : false,
                    SkillsCommentData: skillCommentTmp,
                    SkillsStatus: skillRatingTmp.length > 0 ? true : false,
                    SkillsData: skillRatingTmp,
                    OtherSkillsType: OtherSkillsTypeTmp,
                    OtherSkillsList: otherSkillTmp,
                    skillOverAll: [
                        {
                            name: 'Mandatory Skills',
                            type: 'mandatory',
                            total: mt,
                            length: mc,
                        },
                        {
                            name: 'Optional Skills',
                            type: 'optional',
                            total: ot,
                            length: oc,
                        },
                        {
                            name: 'Soft Skills',
                            type: 'soft',
                            total: st,
                            length: sc,
                        },
                    ],
                }));
            }
        }
    }, [dispatch, roomId, interviewId, feedbackDetails]);
    const [, setWidth] = useState(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return (
        <div className="App">
            {loading ? (
                <Loader />
            ) : feedbackDetails && feedbackDetails?.interviewId?.feedback ? (
                <>
                    <Header data={profileForm} />
                    <Profile data={profileForm} />
                    <OverallSkills data={feedbackForm} />
                    <OtherSkills data={feedbackForm} />
                    {feedbackForm?.SkillsStatus && <Skills data={feedbackForm?.SkillsData} />}
                    <SkillsComment
                        feedbackData={feedbackForm?.FinalRemarksData}
                        feedbackStatus={feedbackForm?.FinalRemarksStatus}
                        data={feedbackForm?.SkillsCommentData}
                        ScreeningQuestionsStatus={feedbackForm?.ScreeningQuestionsStatus}
                        screeningQuestionsData={feedbackForm?.ScreeningQuestionsData}
                    />
                    {feedbackForm?.CodeSnippetStatus && <CodeSnippet data={feedbackForm?.CodeSnippetData} />}
                    {feedbackForm?.codeImagesStatus && <CodeSnippetImages data={feedbackForm?.codeImagesData} />}
                    {feedbackForm?.ScreenshotsStatus && <Screenshots data={feedbackForm?.ScreenshotsData} />}
                    {feedbackForm?.proctoringLogs?.status && (
                        <Proctoring
                            message={feedbackForm?.proctoringLogs?.messages}
                            screenshots={feedbackForm?.proctoringLogs?.screenshots}
                        />
                    )}
                    <Footer data={profileForm} />
                </>
            ) : (
                <Other page="404Page" />
            )}
        </div>
    );
}

const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default FeedbackRoom;
