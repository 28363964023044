import { Skill0, Skill1, Skill2, Skill3, Skill4, Skill5, Skill6, Skill7, Skill8, Skill9, Skill10 } from './SkillRating';

export const getSkill = (skillRating) => {
    let skill = null;
    switch (parseInt(skillRating)) {
        case 0:
            skill = Skill0;
            break;
        case 1:
            skill = Skill1;
            break;
        case 2:
            skill = Skill2;
            break;
        case 3:
            skill = Skill3;
            break;
        case 4:
            skill = Skill4;
            break;
        case 5:
            skill = Skill5;
            break;
        case 6:
            skill = Skill6;
            break;
        case 7:
            skill = Skill7;
            break;
        case 8:
            skill = Skill8;
            break;
        case 9:
            skill = Skill9;
            break;
        case 10:
            skill = Skill10;
            break;
        default:
            skill = Skill0;
            break;
    }
    return skill;
};
export const getStatusList = (type, role) => {
    let list = [];
    if (type === 2) {
        if (role === 'admin') {
            list = [
                {
                    title: 'No Show',
                    code: 'noshow',
                },
            ];
        }
        list = [
            ...list,
            {
                title: 'Very Poor',
                code: 'very_poor',
            },
            {
                title: 'Poor',
                code: 'poor',
            },
            {
                title: 'Average',
                code: 'average',
            },
            {
                title: 'Above Average',
                code: 'above_average',
            },
            {
                title: 'Good',
                code: 'good',
            },
            {
                title: 'Very Good',
                code: 'very_good',
            },
            {
                title: 'Excellent',
                code: 'excellent',
            },
        ];
    } else {
        list = [
            {
                title: 'Shortlisted',
                code: 'shortlisted',
            },
            {
                title: 'Rejected',
                code: 'rejected',
            },
            {
                title: 'No Show',
                code: 'noshow',
            },
            {
                title: 'On Hold',
                code: 'hold',
            },
        ];
    }
    return list;
};
export const getStatusV = (skillRating) => {
    let status = null;
    switch (parseInt(skillRating)) {
        case 0:
            status = { title: 'Very Poor', color: 'status-red' };
            break;
        case 1:
            status = { title: 'Very Poor', color: 'status-red' };
            break;
        case 2:
            status = { title: 'Very Poor', color: 'status-red' };
            break;
        case 3:
            status = { title: 'Poor', color: 'status-red' };
            break;
        case 4:
            status = { title: 'Poor', color: 'status-red' };
            break;
        case 5:
            status = { title: 'Average', color: 'status-yellow' };
            break;
        case 6:
            status = { title: 'Above Average', color: 'status-yellow' };
            break;
        case 7:
            status = { title: 'Good', color: 'status-green' };
            break;
        case 8:
            status = { title: 'Very Good', color: 'status-green' };
            break;
        case 9:
            status = { title: 'Excellent', color: 'status-green' };
            break;
        case 10:
            status = { title: 'Excellent', color: 'status-green' };
            break;
        default:
            status = { title: 'Very Poor', color: 'status-red' };
            break;
    }
    return status;
};
export const getStatus = (skillRating) => {
    let status = null;
    switch (parseInt(skillRating)) {
        case 0:
            status = { title: 'Low Performer', color: 'status-red' };
            break;
        case 1:
            status = { title: 'Low Performer', color: 'status-red' };
            break;
        case 2:
            status = { title: 'Low Performer', color: 'status-red' };
            break;
        case 3:
            status = { title: 'Substandard Performer', color: 'status-red' };
            break;
        case 4:
            status = { title: 'Substandard Performer', color: 'status-red' };
            break;
        case 5:
            status = { title: 'Moderate Performer', color: 'status-yellow' };
            break;
        case 6:
            status = { title: 'Satisfactory Performer', color: 'status-green' };
            break;
        case 7:
            status = { title: 'Competent Performer', color: 'status-green' };
            break;
        case 8:
            status = { title: 'Outstanding Performer', color: 'status-green' };
            break;
        case 9:
            status = { title: 'Exceptional Performer', color: 'status-green' };
            break;
        case 10:
            status = { title: 'Exceptional Performer', color: 'status-green' };
            break;
        default:
            status = { title: 'Low Performer', color: 'status-red' };
            break;
    }
    return status;
};

export const getSoftRating = (title, skillRating) => {
    let status = null;
    if (title === 'Confidence') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Inadequate Confidence',
                    color: 'status-red',
                    comment:
                        'Unlikely to project as a sociable, confident, and assertive individual. Less likely to show self-confidence in achieving the desired objectives and may not strive to surpass own performance.',
                };
                break;
            case 4:
                status = {
                    value: 'Low Self-Assurance',
                    color: 'status-red',
                    comment: 'Unlikely to have knowledge, skills, and abilities more than 10-20%.',
                };
                break;
            case 6:
                status = {
                    value: 'Mildly Confident',
                    color: 'status-yellow',
                    comment:
                        "Somewhat likely to be confident in one's own skills, abilities, and knowledge. Moderately confident while meeting new people and working towards achieving results.",
                };
                break;
            case 8:
                status = {
                    value: 'Bold & Confident',
                    color: 'status-green',
                    comment:
                        "Likely to be confident in one's own skills, abilities, and knowledge. May often appears to be assertive and convincing to stakeholders.",
                };
                break;
            case 10:
                status = {
                    value: 'Extremely Confident',
                    color: 'status-green',
                    comment:
                        'Most likely to be confident about one’s own abilities. Likely to prove as a sociable, confident, and assertive individual.',
                };
                break;
            default:
                status = {
                    value: 'Inadequate Confidence',
                    color: 'status-red',
                    comment:
                        'Unlikely to project as a sociable, confident, and assertive individual. Less likely to show self-confidence in achieving the desired objectives and may not strive to surpass own performance.',
                };
        }
    } else if (title === 'Communication') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Inadequate',
                    color: 'status-red',
                    comment:
                        'Unlikely to have communication etiquette or skills, creating a detrimental effect on business.',
                };
                break;
            case 4:
                status = {
                    value: 'Poor',
                    color: 'status-red',
                    comment:
                        'Unlikely to possess good communication skills. Less likely to communicate well with clients/stakeholders.',
                };
                break;
            case 6:
                status = {
                    value: 'Avg. Communication',
                    color: 'status-yellow',
                    comment:
                        "Somewhat likely to be able to structure one's thoughts and communicate in a clear, concise, and accurate manner. Moderately likely to be a good listener.",
                };
                break;
            case 8:
                status = {
                    value: 'Decent Communication',
                    color: 'status-green',
                    comment:
                        "Likely to be able to structure one's thoughts and communicate in a clear, concise, and accurate manner. Likely to be a good listener and may often participate in business conversations.",
                };
                break;
            case 10:
                status = {
                    value: 'Excellent Communication',
                    color: 'status-green',
                    comment:
                        'Most likely to possess effective communication skills with international clients and team members to resolve tough issues.',
                };
                break;
            default:
                status = {
                    value: 'Inadequate',
                    color: 'status-red',
                    comment:
                        'Unlikely to have communication etiquette or skills, creating a detrimental effect on business.',
                };
        }
    } else if (title === 'Pressure Handling') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Inefficient',
                    color: 'status-red',
                    comment: 'Unlikely to deliver work on time during emergencies.',
                };
                break;
            case 4:
                status = {
                    value: 'Vulnerable',
                    color: 'status-red',
                    comment:
                        'Unlikely to exhibit more than 20-30% capabilities to accomplish work during a critical phase.',
                };
                break;
            case 6:
                status = {
                    value: 'Moderate',
                    color: 'status-yellow',
                    comment:
                        'Somewhat likely to deal effectively with work pressure and gets stressed out easily. Moderately capable to utilize time and resources effectively in demanding and challenging situations.',
                };
                break;
            case 8:
                status = {
                    value: 'Capable',
                    color: 'status-green',
                    comment: 'Likely to handle 80-90% of unexpected workflows.',
                };
                break;
            case 10:
                status = {
                    value: 'Competent',
                    color: 'status-green',
                    comment:
                        'Most likely to accomplish unexpected work on time. Completely reliable and exceptional in handling client requirements.',
                };
                break;
            default:
                status = {
                    value: 'Inefficient',
                    color: 'status-red',
                    comment: 'Unlikely to deliver work on time during emergencies.',
                };
        }
    } else if (title === 'Approach & Attitude') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Extremely Low',
                    color: 'status-red',
                    comment:
                        'Unlikely to stay calm to take difficult decisions. Less likely to show the ability to maintain a positive attitude in challenging situations.',
                };
                break;
            case 4:
                status = {
                    value: 'Poor',
                    color: 'status-red',
                    comment:
                        'Unlikely to be approachable and this attitude negatively impacts the morale of the team. Occasionally delivers work using a strategic approach. Easily gets frustrated.',
                };
                break;
            case 6:
                status = {
                    value: 'Moderate',
                    color: 'status-yellow',
                    comment: 'Somewhat likely to be quick to congratulate coworkers to build trust.',
                };
                break;
            case 8:
                status = {
                    value: 'Positive',
                    color: 'status-green',
                    comment:
                        'Likely to maneuver any obstacles. Finishes work on time and with accuracy. Holds adequate capabilities to create strategic plans and manage work with a positive approach.',
                };
                break;
            case 10:
                status = {
                    value: 'Extremely Positive',
                    color: 'status-green',
                    comment:
                        'Most likely to prove as an asset to the organization. Deals with difficult situations with incredible perseverance. Works with minimal supervision and less training.',
                };
                break;
            default:
                status = {
                    value: 'Extremely Low',
                    color: 'status-red',
                    comment:
                        'Unlikely to stay calm to take difficult decisions. Less likely to show the ability to maintain a positive attitude in challenging situations.',
                };
        }
    } else if (title === 'Problem Solving') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Extremely Low',
                    color: 'status-red',
                    comment:
                        'Unlikely to effectively analyze a scenario and choose the best solution. Less likely to possess skills such as identifying, reasoning, and problem-solving.',
                };
                break;
            case 4:
                status = {
                    value: 'Substandard',
                    color: 'status-red',
                    comment:
                        'Unlikely familiar with problem-solving and formulating strategies. Less likely competent to explain the rationale behind the solution to a problem.',
                };
                break;
            case 6:
                status = {
                    value: 'Moderate',
                    color: 'status-yellow',
                    comment:
                        'Somewhat likely to identify potential problems or investigate them in greater depth and choose the best solution. Identifies risks and takes appropriate actions.',
                };
                break;
            case 8:
                status = {
                    value: 'Problem Solver',
                    color: 'status-green',
                    comment:
                        'Likely to use a variety of techniques to solve problems and is willing to try new approaches. Easily evaluates resources and maintains team harmony.',
                };
                break;
            case 10:
                status = {
                    value: 'Excellent',
                    color: 'status-green',
                    comment:
                        'Most likely to identify potential problems or investigate them in greater depth. Exhibits out-of-the-box problem-solving skills to address any challenging issues.',
                };
                break;
            default:
                status = {
                    value: 'Extremely Low',
                    color: 'status-red',
                    comment:
                        'Unlikely to effectively analyze a scenario and choose the best solution. Less likely to possess skills such as identifying, reasoning, and problem-solving.',
                };
        }
    } else if (title === 'Result Oriented') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Extremely Low',
                    color: 'status-red',
                    comment:
                        'Unlikely to be consistent in defining goals and objectives. Less likely to work proactively to achieve results on time.',
                };
                break;
            case 4:
                status = {
                    value: 'Lacks Focus',
                    color: 'status-red',
                    comment:
                        'Unlikely to accept responsibility for missed goals. Less likely to avoid distractions to achieve performance goals.',
                };
                break;
            case 6:
                status = {
                    value: 'Passable',
                    color: 'status-yellow',
                    comment:
                        'Somewhat likely to be able to achieve results on time. Moderately enthusiastic while working on challenging tasks.',
                };
                break;
            case 8:
                status = {
                    value: 'Highly Enthusiastic',
                    color: 'status-green',
                    comment:
                        'Likely to set high-performance standards for self and team. Likely to demonstrate the ability to work with dedication and commitment.',
                };
                break;
            case 10:
                status = {
                    value: 'Goal Driven',
                    color: 'status-green',
                    comment:
                        'Most likely to be intrinsically motivated to work on assigned tasks and deliver high-quality output. Likely to try new strategies to accomplish challenging goals and remain focused despite obstacles.',
                };
                break;
            default:
                status = {
                    value: 'Extremely Low',
                    color: 'status-red',
                    comment:
                        'Unlikely to be consistent in defining goals and objectives. Less likely to work proactively to achieve results on time.',
                };
        }
    } else if (title === 'Decision Making') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Bad Decision Maker',
                    color: 'status-red',
                    comment:
                        'Unlikely to be able to think rationally and thus may not be able to contemplate between positive and negative situations.',
                };
                break;
            case 4:
                status = {
                    value: 'Dysfunctional',
                    color: 'status-red',
                    comment:
                        'Unlikely to make wise decisions. Therefore, requires training to improve decision-making skills.',
                };
                break;
            case 6:
                status = {
                    value: 'Moderate',
                    color: 'status-yellow',
                    comment:
                        'Somewhat likely to take inputs before making important decisions. May occasionally weigh the possible consequences of decisions.',
                };
                break;
            case 8:
                status = {
                    value: 'Quite Decisive',
                    color: 'status-green',
                    comment:
                        'Likely to remain calm in stressful situations. Likely to be good in decision-making by considering 360 degree-analysis of the story.',
                };
                break;
            case 10:
                status = {
                    value: 'Extremely Decisive',
                    color: 'status-green',
                    comment:
                        'Most likely to make well-informed, effective, or timely decisions. Likely to take into account all possible consequences of decisions.',
                };
                break;
            default:
                status = {
                    value: 'Bad Decision Maker',
                    color: 'status-red',
                    comment:
                        'Unlikely to be able to think rationally and thus may not be able to contemplate between positive and negative situations.',
                };
        }
    } else if (title === 'Team Management') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Extremely Lousy',
                    color: 'status-red',
                    comment: 'Unlikely to set reasonable expectations and goals.',
                };
                break;
            case 4:
                status = {
                    value: 'Poor Admin. & Control',
                    color: 'status-red',
                    comment:
                        'Unlikely to take an interest in the work of team members or coach them on how to execute tasks effectively. Less likely to provide constructive feedback and improve the performance of the team.',
                };
                break;
            case 6:
                status = {
                    value: 'Decent Synergism',
                    color: 'status-yellow',
                    comment:
                        'Somewhat likely to effectively manage the team. Occasionally guides the team, tracks team progress, and anticipates roadblocks. Motivates team members and provides meaningful feedback.',
                };
                break;
            case 8:
                status = {
                    value: 'People Manager',
                    color: 'status-green',
                    comment:
                        'Likely to take an interest in the work of team members and coach them on how to execute tasks effectively. Provides constructive suggestions and allocates work based on the skill set of team members.',
                };
                break;
            case 10:
                status = {
                    value: 'Highly Efficient',
                    color: 'status-green',
                    comment:
                        "Most Likely to provide constructive feedback to team members. Likely to share one's useful learnings and experiences with team members to help them develop skills and abilities.",
                };
                break;
            default:
                status = {
                    value: 'Extremely Lousy',
                    color: 'status-red',
                    comment:
                        'Sometimes sets an unreasonably high expectation for his/her team and cansSends mixed signals to the team regarding goals and day-to-day activities.',
                };
        }
    } else if (title === 'Accountability') {
        switch (skillRating) {
            case 2:
                status = {
                    value: 'Extremely Unaccountable',
                    color: 'status-red',
                    comment:
                        'Unlikely to be accountable or accept responsibility for the actions taken by oneself or the team. Less likely to ensure work is completed on time.',
                };
                break;
            case 4:
                status = {
                    value: 'Low Accountability',
                    color: 'status-red',
                    comment:
                        "Unlikely to take responsibility for one's own actions or decisions. Unlikely to take one's duties and responsibilities seriously. Less likely to be a self-starter.",
                };
                break;
            case 6:
                status = {
                    value: 'Somewhat Accountable',
                    color: 'status-yellow',
                    comment:
                        "Somewhat likely to make sure one's own and team's tasks are completed on time. Moderately accountable for one's own and team's failures and successes.",
                };
                break;
            case 8:
                status = {
                    value: 'Answerable',
                    color: 'status-green',
                    comment:
                        "Likely to take responsibility for one's own actions and decisions. Likely to take ownership of delivering quality work by adhering to timelines. Likely to work with minimal supervision.",
                };
                break;
            case 10:
                status = {
                    value: 'Extremely Reliable',
                    color: 'status-green',
                    comment:
                        'Most likely committed to delivering the work before the deadline. Quickly moves on with a solution when held accountable for workflows.',
                };
                break;
            default:
                status = {
                    value: 'Extremely Unaccountable',
                    color: 'status-red',
                    comment:
                        'Unlikely to be accountable or accept responsibility for the actions taken by oneself or the team. Less likely to ensure work is completed on time.',
                };
        }
    }
    return status;
};
export const capitalizeAll = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const getLanguages = () => {
    let languages = [
        { id: 45, name: 'Assembly (NASM 2.14.02)' },
        { id: 46, name: 'Bash (5.0.0)' },
        { id: 47, name: 'Basic (FBC 1.07.1)' },
        { id: 75, name: 'C (Clang 7.0.1)' },
        { id: 76, name: 'C++ (Clang 7.0.1)' },
        { id: 48, name: 'C (GCC 7.4.0)' },
        { id: 52, name: 'C++ (GCC 7.4.0)' },
        { id: 49, name: 'C (GCC 8.3.0)' },
        { id: 53, name: 'C++ (GCC 8.3.0)' },
        { id: 50, name: 'C (GCC 9.2.0)' },
        { id: 54, name: 'C++ (GCC 9.2.0)' },
        { id: 86, name: 'Clojure (1.10.1)' },
        { id: 51, name: 'C# (Mono 6.6.0.161)' },
        { id: 77, name: 'COBOL (GnuCOBOL 2.2)' },
        { id: 55, name: 'Common Lisp (SBCL 2.0.0)' },
        { id: 90, name: 'Dart (2.19.2)' },
        { id: 56, name: 'D (DMD 2.089.1)' },
        { id: 57, name: 'Elixir (1.9.4)' },
        { id: 58, name: 'Erlang (OTP 22.2)' },
        { id: 44, name: 'Executable' },
        { id: 87, name: 'F# (.NET Core SDK 3.1.202)' },
        { id: 59, name: 'Fortran (GFortran 9.2.0)' },
        { id: 60, name: 'Go (1.13.5)' },
        { id: 95, name: 'Go (1.18.5)' },
        { id: 88, name: 'Groovy (3.0.3)' },
        { id: 61, name: 'Haskell (GHC 8.8.1)' },
        { id: 91, name: 'Java (JDK 17.0.6)' },
        { id: 62, name: 'Java (OpenJDK 13.0.1)' },
        { id: 63, name: 'JavaScript (Node.js 12.14.0)' },
        { id: 93, name: 'JavaScript (Node.js 18.15.0)' },
        { id: 78, name: 'Kotlin (1.3.70)' },
        { id: 64, name: 'Lua (5.3.5)' },
        { id: 89, name: 'Multi-file program' },
        { id: 79, name: 'Objective-C (Clang 7.0.1)' },
        { id: 65, name: 'OCaml (4.09.0)' },
        { id: 66, name: 'Octave (5.1.0)' },
        { id: 67, name: 'Pascal (FPC 3.0.4)' },
        { id: 85, name: 'Perl (5.28.1)' },
        { id: 68, name: 'PHP (7.4.1)' },
        { id: 43, name: 'Plain Text' },
        { id: 69, name: 'Prolog (GNU Prolog 1.4.5)' },
        { id: 70, name: 'Python (2.7.17)' },
        { id: 92, name: 'Python (3.11.2)' },
        { id: 71, name: 'Python (3.8.1)' },
        { id: 80, name: 'R (4.0.0)' },
        { id: 72, name: 'Ruby (2.7.0)' },
        { id: 73, name: 'Rust (1.40.0)' },
        { id: 81, name: 'Scala (2.13.2)' },
        { id: 82, name: 'SQL (SQLite 3.27.2)' },
        { id: 83, name: 'Swift (5.2.3)' },
        { id: 74, name: 'TypeScript (3.7.4)' },
        { id: 94, name: 'TypeScript (5.0.3)' },
        { id: 84, name: 'Visual Basic.Net (vbnc 0.0.0.5943)' },
    ];
    return languages;
};

export const getCodeLanguages = () => {
    let languages = [
        { value: 45, label: 'Assembly (NASM 2.14.02)' },
        { value: 46, label: 'Bash (5.0.0)' },
        { value: 47, label: 'Basic (FBC 1.07.1)' },
        { value: 75, label: 'C (Clang 7.0.1)' },
        { value: 76, label: 'C++ (Clang 7.0.1)' },
        { value: 48, label: 'C (GCC 7.4.0)' },
        { value: 52, label: 'C++ (GCC 7.4.0)' },
        { value: 49, label: 'C (GCC 8.3.0)' },
        { value: 53, label: 'C++ (GCC 8.3.0)' },
        { value: 50, label: 'C (GCC 9.2.0)' },
        { value: 54, label: 'C++ (GCC 9.2.0)' },
        { value: 86, label: 'Clojure (1.10.1)' },
        { value: 51, label: 'C# (Mono 6.6.0.161)' },
        { value: 77, label: 'COBOL (GnuCOBOL 2.2)' },
        { value: 55, label: 'Common Lisp (SBCL 2.0.0)' },
        { value: 90, label: 'Dart (2.19.2)' },
        { value: 56, label: 'D (DMD 2.089.1)' },
        { value: 57, label: 'Elixir (1.9.4)' },
        { value: 58, label: 'Erlang (OTP 22.2)' },
        { value: 44, label: 'Executable' },
        { value: 87, label: 'F# (.NET Core SDK 3.1.202)' },
        { value: 59, label: 'Fortran (GFortran 9.2.0)' },
        { value: 60, label: 'Go (1.13.5)' },
        { value: 95, label: 'Go (1.18.5)' },
        { value: 88, label: 'Groovy (3.0.3)' },
        { value: 61, label: 'Haskell (GHC 8.8.1)' },
        { value: 91, label: 'Java (JDK 17.0.6)' },
        { value: 62, label: 'Java (OpenJDK 13.0.1)' },
        { value: 63, label: 'JavaScript (Node.js 12.14.0)' },
        { value: 93, label: 'JavaScript (Node.js 18.15.0)' },
        { value: 78, label: 'Kotlin (1.3.70)' },
        { value: 64, label: 'Lua (5.3.5)' },
        { value: 89, label: 'Multi-file program' },
        { value: 79, label: 'Objective-C (Clang 7.0.1)' },
        { value: 65, label: 'OCaml (4.09.0)' },
        { value: 66, label: 'Octave (5.1.0)' },
        { value: 67, label: 'Pascal (FPC 3.0.4)' },
        { value: 85, label: 'Perl (5.28.1)' },
        { value: 68, label: 'PHP (7.4.1)' },
        { value: 43, label: 'Plain Text' },
        { value: 69, label: 'Prolog (GNU Prolog 1.4.5)' },
        { value: 70, label: 'Python (2.7.17)' },
        { value: 92, label: 'Python (3.11.2)' },
        { value: 71, label: 'Python (3.8.1)' },
        { value: 80, label: 'R (4.0.0)' },
        { value: 72, label: 'Ruby (2.7.0)' },
        { value: 73, label: 'Rust (1.40.0)' },
        { value: 81, label: 'Scala (2.13.2)' },
        { value: 82, label: 'SQL (SQLite 3.27.2)' },
        { value: 83, label: 'Swift (5.2.3)' },
        { value: 74, label: 'TypeScript (3.7.4)' },
        { value: 94, label: 'TypeScript (5.0.3)' },
        { value: 84, label: 'Visual Basic.Net (vbnc 0.0.0.5943)' },
    ];
    return languages;
};
