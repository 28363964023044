import React from 'react';
import moment from 'moment';
import MaleAvatar from '../../assets/images/MaleAvatar.svg';
import RecommendedGreen from '../../assets/images/RecommendedGreen.svg';
import RecommendedRed from '../../assets/images/RecommendedRed.svg';
import RecommendedYellow from '../../assets/images/RecommendedYellow.svg';
import SuspectedProxy from '../../assets/images/SuspectedProxy.svg';
import { CircularProgressBarPage } from '../OtherComponents/CircularProgressBarPage';
import { capitalizeAll } from '../OtherComponents/Helpers';
export const Profile = ({ data }) => {
    return (
        <>
            <div className="profile-div">
                <div className="profile-img-div">
                    <img className="profile-img-logo" src={data?.image ? data?.image : MaleAvatar} alt="Logo" />
                </div>
                <div className="profile-details-div desktop-mode">
                    <span className="common-profile-text profile-intId">{data?.code}</span>
                    <span className="common-profile-text profile-details-text">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.position)} |{' '}
                        {Number(data?.experience).toFixed(1)} years
                    </span>
                    <span className="common-profile-text profile-details-text">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-contact">
                        Date of Interview: {moment.utc(data?.interviewDate).format('llll')}
                    </span>
                </div>
                <div className="profile-details-status">
                    {/* <img
                        className="profile-recommended-img"
                        src={
                            data?.recommendedMode === 3
                                ? RecommendedRed
                                : data?.recommendedMode === 2
                                ? RecommendedYellow
                                : RecommendedGreen
                        }
                        alt="Logo"
                    /> */}

                    <CircularProgressBarPage rating={data?.rating} />
                    {data?.suspectedProxy && <img className="profile-suspected" src={SuspectedProxy} alt="Logo" />}
                </div>
            </div>
            <div className="profile-details-div mobile-mode">
                <div className="profile-details-div-1">
                    <span className="common-profile-text profile-intId">{data?.code}</span>
                    <span className="common-profile-text profile-details-text">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.position)} |{' '}
                        {Number(data?.experience).toFixed(1)} years
                    </span>
                    <span className="common-profile-text profile-details-text">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-contact">
                        Date of Interview: {moment(data?.interviewDate).format('llll')}
                    </span>
                </div>
            </div>
        </>
    );
};
